/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./custom.scss"
const $ = require("jquery")

export const onInitialClientRender = () => {
  $(document).ready(function () {
    setTimeout(() => {
        $(".services .container .row #right .wrapper button:nth-child(2)").click();
      }, "3000")
      setTimeout(() => {
        $(".services .container .row #right .wrapper button:nth-child(3)").click();
      }, "6000")
      setTimeout(() => {
        $(".services .container .row #right .wrapper button:nth-child(4)").click();
      }, "9000")
      setTimeout(() => {
        $(".services .container .row #right .wrapper button:nth-child(5)").click();
      }, "12000")
      setTimeout(() => {
        $(".services .container .row #right .wrapper button:nth-child(6)").click();
      }, "15000")
      setTimeout(() => {
        $(".services .container .row #right .wrapper button:nth-child(1)").click();
      }, "18000")
      setTimeout(() => {
        $(".services .container .row #right .wrapper button:nth-child(2)").click();
      }, "21000")
      setTimeout(() => {
        $(".services .container .row #right .wrapper button:nth-child(3)").click();
      }, "24000")
      setTimeout(() => {
        $(".services .container .row #right .wrapper button:nth-child(4)").click();
      }, "27000")
      setTimeout(() => {
        $(".services .container .row #right .wrapper button:nth-child(5)").click();
      }, "30000")
      setTimeout(() => {
        $(".services .container .row #right .wrapper button:nth-child(6)").click();
      }, "33000")
  });
}